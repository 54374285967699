.tree-view-panel {
  background-color: $main-white;
}

.tree-view {
  margin-right: -20px;

  .label {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
