.new-deal {
  height: 100%;
  padding-top: 20px;
  display: flex;
  flex-direction: column;

  .stepper {
    padding-left: 25%;
    padding-right: 25%;
  }

  hr.MuiDivider-vertical {
    margin-right: -1.5px;
  }

  .content {
    flex-grow: 1;

    .form {
      height: 100%;
      display: flex;
      width: 100%;

      .right {
        width: 100%;
      }

      .split-panel {
        left: -20px;
        box-shadow: 4px 0 10px rgba(0, 0, 0, 0.15);
        clip-path: inset(0px -15px 0px 0px);

        .left {
          width: 50vw;
          padding: 20px 20px 0;

          .button-container {
            padding-right: 40px;
            padding-bottom: 20px;
          }

          .import-section {
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h2 {
              font-size: 28px;
            }

            button {
              height: 28px;
            }
          }
        }
      }
    }
  }
}
