.stepper-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .current-step {
    font-family: MB Corpo A Title Cond WEB;
    font-size: 40px;
    font-weight: 400;
  }

  button {
    height: 28px;
  }
}
