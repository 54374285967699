.new-customer {
  .new-customer-header {
    display: flex;
    align-items: center;
    min-height: 100px;

    h1 {
      font-size: 40px;
      font-weight: 400;
      flex-grow: 1;
    }

    button,
    a {
      margin-left: 10px;
      height: 28px;
    }
  }

  .customer-type-selction {
    background-color: #252d36;
    color: $main-white;
    margin-left: -20px;
    margin-right: -20px;
    padding: 30px;
  }

  .phone-field {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    > :first-child {
      flex: 0 1 100px;
    }

    > :nth-child(2) {
      flex: 1;
    }
  }
}

.private-customer-form {
  padding-top: 20px;

  div.MuiBox-root {
    padding: 0;
  }

  .personal-data,
  .income,
  .finance {
    &.MuiGrid-root {
      padding: 24px 0;
    }

    > div {
      display: flex;
      flex-direction: column;
    }

    .split-column {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 40px;

      > div {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      > div.consent-form {
        margin-left: 8px;
        gap: 40px;
      }
    }

    .form-radio-button {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > label {
        width: 8em;
      }
    }
  }

  .income > div {
    flex: 0 1 25%;
  }
}
