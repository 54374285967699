.split-panel {
  position: relative;
  flex-shrink: 0;
  overflow: visible;

  .left {
    height: 100%;
    display: flex;
    flex-direction: column;

    .fade-left {
      height: 100%;
    }

    .button-container {
      position: sticky;
      bottom: 0;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
