.App {
  .MuiButton-root {
    box-shadow: none;

    &.white {
      background-color: $main-white;
      color: $main-black;
      border: 1px solid $main-black;

      &[disabled] {
        color: $main-grey750;
        border-color: $main-grey750;
      }
    }
  }
}
