.MuiFormControl-root .MuiInput-root {
  .MuiIconButton-root {
    margin-right: -8px;
  }
  .MuiSvgIcon-root {
    fill: #0078d6;
  }
}

.MuiFormControlLabel-root.Mui-error {
  color: #d92121;
}
