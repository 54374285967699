main {
  padding: 0 20px;
  flex-grow: 1;
}

h1,
h2,
h3 {
  font-family: "MB Corpo A Title Condensed", "MB Corpo S Title Condensed";
  font-weight: 400;
}
