.upload-container {
  border: 1px dashed $main-grey500;
  border-radius: 2px;
  width: 100%;
  margin-bottom: 40px;
  height: 200px;

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      padding: 8px 0 16px 0;
    }

    .subtitle,
    .info {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }

    .info {
      color: $main-grey500;
      padding-bottom: 16px;
    }

    .process {
      width: 240px;
      padding: 4px;
      margin: 16px 0 12px;
    }
  }

  .dragging-overlay {
    background: #0078d6;
    opacity: 0.95;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px;

    & > div {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;
      color: $main-white;
    }
  }
}

.file-uploader__input {
  display: none;
}
