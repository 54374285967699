.search-panel {
  background-color: #252d36;
  color: $main-white;
  margin-left: -20px;
  margin-right: -20px;
  padding: 30px;

  .toggle-search {
    font-size: 14px;
    color: white;
  }

  .reset {
    font-size: 14px;
    color: white;
  }

  &.two-items {
    .search-container {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: auto 180px;
    }
  }

  &.three-items {
    .search-container {
      display: grid;
      grid-gap: 15px;
      grid-template-columns: 30% 140px 180px;
    }
  }

  &.advanced {
    .button-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      button[type="submit"] {
        height: 64px;
        margin-bottom: 20px;
      }
    }
  }
}
