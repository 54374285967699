.deals {
  .deal-list {
    margin-top: 16px;
  }

  tr.MuiTableRow-hover {
    cursor: pointer;
  }

  tr.MuiTableRow-root .MuiSvgIcon-root {
    fill: rgba(0, 0, 0, 0.6);
  }
}
